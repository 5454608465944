import React from 'react'

function Home() {
  return (
    <section className="bg-gray-50 w-screen dark:bg-gray-900">
    <div className="flex flex-col items-center justify-center px-6 py-60 mx-auto md:h-screen lg:py-0  bg-stone-200">
      <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <div className='flex justify-center items-center'>

              <h2 className='text-blue-800 text-xl font-bold'>
                Kindly check email to reset password.
              </h2>
            </div>
        </div>

    </div>
    </section>
  )
}

export default Home