import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Success from "./components/Success";
import Failure from "./components/Failure";
import Reset from "./components/Reset";

function App() {
  console.log("Welcome to the Home");
  console.log("your are accessing the",window.location.href)
  return (
    <Router>
      <Routes>
        <Route path="/" element = {<Home />} />
        <Route path="/reset-password" element = {<Reset />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
      </Routes>
  </Router>
  );
}

export default App;
