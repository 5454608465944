import React, { useState } from "react";
import { useContext } from "react";
import logo from "../assets/image/newPoseIt.png";
import axios from "axios";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
// import {Redirect} from 'react-router'
export default function Reset() {
  const [params] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const token = params.get("token")
  const navigate = useNavigate();

  const handleSubmit = () =>{
  
    console.log(password,confirmPassword,confirmPassword == "")
    if(password == "" || confirmPassword == ""){
    
      setErrorMessage("Empty password found !!")
      return;
    }
    if(password !== confirmPassword){
      setErrorMessage("Password don't match")
      return;
    }

    const body = {
      "newPassword" : password,
      "confirmPassword" : confirmPassword
    }

    const params = {
      token : token
    }

    axios
    .post(
      'https://poseit.org/vai/common/reset-password',body,{params}
    )
    .then((res) =>{
      if(res.data.code === 200){
        navigate("/success",{state:{ message : res.data.data}});
      }
      else navigate("/failure",{state:{ message : res.data.data}})

    })
    .catch((e) =>{
      navigate('/failure',{state:{ message : "Error caught "}})
    })
  }
  
  return (
    <div>
      <section className="bg-gray-50 w-screen dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-60 mx-auto md:h-screen lg:py-0  bg-stone-200">
          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h2 className="mb-1 text-4xl font-bold leading-tight text-center tracking-tight text-gray-900 md:text-4xl dark:text-white">
              Change Password
            </h2>
            <img className="logo" src={logo}/>
            <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div>
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm password
                </label>
                <input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  onChange={(e) =>setConfirmPassword(e.target.value)}
                ></input>
              </div>
            </form>
            <button
              onClick={() => handleSubmit()}
              className="w-full mt-5 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Submit
            </button>
            <div className="flex justify-center items-center mt-2">
              <h5 className="font-bold text-red-700">{errorMessage}</h5>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
