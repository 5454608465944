import React from 'react'
import { useLocation } from 'react-router'

function Success() {
  const location = useLocation();
  const message = location.state !== null ? location.state.message : "";
  return (
    <section className="bg-gray-50 w-screen dark:bg-gray-900">
    <div className="flex flex-col items-center justify-center px-6 py-60 mx-auto md:h-screen lg:py-0  bg-stone-200">
      <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <div className='flex flex-col justify-center items-center'>

              <h2 className='text-green-800 text-xl font-bold'>Success !!</h2>
              <h2 className='text-blue-800 text-xl font-bold'>{message}</h2>
            </div>
        </div>

    </div>
    </section>
  )
}

export default Success